import { useState } from 'react';
import './style/global.scss';

function Main() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="main">
            <header className="container">
                <img className="logo" src={require('./img/logo.svg').default} />
                <nav className={isMenuOpen ? 'active' : ''}>
                    <a href="#aboutus">About Us</a>
                    <a href="#services">Services</a>
                    <a href="#faq">FAQ</a>
                    <a href="#contactus" className="main_button mobile">Contact Us</a>
                </nav>
                <a href="#contactus" className="main_button desktop">Contact Us</a>
                <img className="burger" onClick={() => setIsMenuOpen(!isMenuOpen)} src={require('./img/burger.svg').default} />
            </header>
            <div className="main_wrapper container">
                <div className="left_wrapper">
                    <h1>
                        <strong>More</strong> <br />
                        than marketing 
                    </h1>
                    <p className="subheader">Grow revenue with elit-web’s data-driven digital solutions</p>
                    <a href="#contactus" className="main_button orange">
                        Contact Us
                        <img className="count_img" src={require('./img/button_arrow.svg').default} />
                    </a>
                    <img className="main_banner_mobile" src={require('./img/main_banner_mobile.png')} />
                    <div className="count_wrapper">
                        <div className="item">
                            <div className="top_block">
                                <img className="count_img" src={require('./img/count1.svg').default} />
                                <span className="value">100%</span>
                            </div>
                            <span className="name">Set up by Eydea Media</span>
                        </div>
                        <div className="item">
                            <div className="top_block">
                                <img className="count_img" src={require('./img/count2.svg').default} />
                                <span className="value">20%</span>
                            </div>
                            <span className="name">Avg increase in roi</span>
                        </div>
                        <div className="item">
                            <div className="top_block">
                                <img className="count_img" src={require('./img/count3.svg').default} />
                                <span className="value">1B</span>
                            </div>
                            <span className="name">Data points that drive decision making</span>
                        </div>
                    </div>
                </div>
                <div className="right_part">
                    <img className="main_banner" src={require('./img/main_banner.png')} />
                </div>
            </div>
            {/* <div className="container">
                <div className="crypto_wrapper">
                    <img src={require('./img/crypto1.png')} />
                    <img src={require('./img/crypto2.png')} />
                    <img src={require('./img/crypto3.png')} />
                    <img src={require('./img/crypto4.png')} />
                </div>
            </div> */}
            <div id="aboutus"></div>
            <div className="why_wrapper container">
                <div className="left_wrapper">
                    <p>Discover how we can help your business grow</p>
                    <a href="#contactus" className="main_button orange">Contact Us</a>
                </div>
                <div className="right_part">
                    <span>Why choose us?</span>
                </div>
            </div>
            <div className="monitor_wrapper container">
                <div className="left_wrapper">
                    <img src={require('./img/monitor_wrapper.png')} />
                </div>
                <div className="right_wrapper">
                    <div className="item">
                        <span className="value">01</span>
                        <p>Full-cycle marketing agency experience. We support businesses of any marketing maturity level. </p>
                    </div>
                    <div className="item">
                        <span className="value">02</span>
                        <p>Competitive pricing. We offer full-cycle marketing agency services for a fraction of the cost. </p>
                    </div>
                    <div className="item">
                        <span className="value">03</span>
                        <p>Low risk. With our digital marketing agency, US businesses get a transparent pricing structure, award-winning expertise and a sophisticated marketing effort. </p>
                    </div>
                </div>
            </div>
            <div id="services"></div>
            <div className="forewarned_wrapper container">
                <h3>Our Services </h3>
                <span className="subtext">Marketing Services for Your Business Growth </span>
                <div className="content_wrapper">
                    <div className="item">
                        <span>Search Engine Optimization </span>
                        <p>Rank in the TOP of search engines and outperform your competitors with a tool that ensures long-term ROI. Our SEO strategies are designed to increase your online visibility, attract organic traffic, and drive qualified leads to your website. By focusing on keyword optimization, content quality, and technical SEO, we help you achieve a strong digital presence that consistently delivers results </p>
                    </div>
                    <div className="item">
                        <span>White Label SEO </span>
                        <p>Deliver exceptional results for your clients with white-label SEO services. We work behind the scenes to enhance your brand’s reputation, providing top-notch SEO strategies that align with your client’s business objectives. Our comprehensive approach includes everything from keyword research and link building to content creation and performance monitoring </p>
                    </div>
                </div>
            </div>
            <div className="about_wrapper">
                <div className="container">
                    <h3>A little about <strong>EYEDEA</strong></h3>
                    <p>
                        As a leading software development company in the UAE, is committed to providing innovative tools and strategies to empower enterprises. Our software will help you optimize operations, redefine processes, and unlock new growth opportunities to improve your productivity and minimize your downtime. With us, you get the best talent in the industry and a result-driven approach to architect scalable, secure, and user-centric solutions.
                        <br /><br /><br />
                        <strong>Create The Best Software For Your Business. </strong> With years of experience as a software development firm, we have created a talented pool of skilled professionals who successfully deliver projects globally.  As a top IT software development company, we take a collaborative approach focused on tangible outcomes to create robust and scalable digital ecosystems. Our strategic tie-ups provide end-to-end implementation from ideation to maintenance. 
                    </p>
                </div>
            </div>
            <div className="account_types container">
                <h3>Affordable Support Pricing </h3>
                <span className="subtext">We offer website and app maintenance at competitive prices. Please see details below or contact us for custom</span>
                <div className="content_wrapper">
                    <div className="item">
                        <div className="top_block">
                            <div className="name">Online Presence</div>
                        </div>
                        <span className="min_text">From:</span>
                        <span className="price">£150</span>
                        <ul>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Boost site visibility with advanced SEO.</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Engage followers with targeted social media.</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Create high-quality, engaging content.</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Produce compelling videos for engagement.</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Generate leads with SEO and PPC strategies.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="item">
                        <div className="top_block">
                            <div className="name">Web Design</div>
                        </div>
                        <span className="min_text">From:</span>
                        <span className="price">£99</span>
                        <ul>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Expert Web Development Solutions</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Customized Designs for Success</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Affordable Starter Websites</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Advanced eCommerce Features</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Ongoing Maintenance & Support</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="item">
                        <div className="top_block double">
                            <div className="name">App Development</div>
                            <div className="special">Popular</div>
                        </div>
                        <span className="min_text">From:</span>
                        <span className="price">£999</span>
                        <ul>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Personalized App for You</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Cryptocurrency and metal trading</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Custom App Development</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>iOS App Development</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>Android App Development</span>
                                </div>
                            </li>
                            <li>
                                <div className="info">
                                    <img src={require('./img/check.svg').default} />
                                    <span>eCommerce App Development</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="faq"></div>
            <div className="frequently_wrapper container">
                <h3>Frequently Asked Questions</h3>
                <span className="subtext">In this section you will find answers to the most common questions about our services and principles of work. If you have not found the answer to your question, you can contact an online consultant on our website or write to us by e-mail via the feedback form.</span>
                <div className="content_wrapper">
                    <div class="dropdown_block">
                        <input id="#check1" name="#check1" type="checkbox" /> 
                        <label for="#check1">What services does your digital marketing agency offer? </label>
                        <div class="content">
                            <p>Our full-service digital marketing agency provides a comprehensive range of marketing services, including SEO, PPC campaigns, social media marketing, and content marketing. We tailor our strategies to help your business achieve its online objectives by utilizing keyword optimization, conversion optimization (CRO), and funnel approaches. Our team focuses on creating easy-to-understand, effective strategies that deliver real results. With our help, your business can reach more customers and grow </p>
                        </div>
                    </div>
                    <div class="dropdown_block">
                        <input id="#check2" name="#check2" type="checkbox" /> 
                        <label for="#check2">How does your marketing team measure results? </label>
                        <div class="content">
                            <p>We measure results using a combination of quantitative and qualitative metrics, including ROI tracking, conversions, website traffic, keyword ranking improvements, and social media engagement. Elit-web is one of the most famous marketing agencies that provides detailed analytics reporting to showcase how efforts help businesses achieve their goals and drive success. </p>
                        </div>
                    </div>
                    <div class="dropdown_block">
                        <input id="#check3" name="#check3" type="checkbox" /> 
                        <label for="#check3">What kind of clients do you work with?</label>
                        <div class="content">
                            <p>Elit-web collaborates with businesses in various industries, ranging from small startups to large enterprises. We specialize in B2C and B2B sectors, including e-commerce, SaaS, healthcare, law services, and HVAC, helping your business grow through our expert strategies. </p>
                        </div>
                    </div>
                    <div class="dropdown_block">
                        <input id="#check4" name="#check4" type="checkbox" /> 
                        <label for="#check4">How can I track my online marketing results?</label>
                        <div class="content">
                            <p>We provide detailed and comprehensive reports to ensure complete transparency. Our reports include analytics that covers every aspect of a marketing campaign, showing both successes and areas for improvement to make data-driven decisions. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contactus"></div>
            <div className="contactus_wrapper">
                <div className="container">
                    <h3>Contact us</h3>
                    <span className="subtext">Our support works non-stop to ensure that you can contact our managers with any question you may have. We are ready to help you and give you sound advice for your success</span>
                    <div className="content_wrapper">
                        <div className="input_block">
                            <label htmlFor="">Your Name</label>
                            <input type="text" placeholder='Your Name' />
                        </div>
                        <div className="input_block">
                            <label htmlFor="">Email</label>
                            <input type="text" placeholder='Email' />
                        </div>
                        <div className="input_block">
                            <label htmlFor="">Question Subject</label>
                            <input type="text" placeholder='Question Subject' />
                        </div>
                        <div className="input_block text">
                            <label htmlFor="">Message</label>
                            <textarea type="text" placeholder='Message' />
                        </div>
                        <a href="#" className="main_button orange">Submit</a>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="top_block">
                        <img src={require('./img/logo.svg').default} />
                        <div className="nav">
                            <a href="#aboutus">About Us</a>
                            <a href="#services">Services</a>
                            <a href="#faq">FAQ</a>
                            <a href="#contactus">Contact Us</a>
                        </div>
                    </div>
                    <p className="copy">Innovating Tomorrow, Today. Your partner in digital transformation, delivering cutting-edge technology solutions tailored to your needs. </p>
                </div>
            </footer>
        </div>
    );
}

export default Main;
